<template>
  <div v-if="mainPackagesList.length > 0">
    <b-card no-body>
      <b-card-header class="py-0">
        <b-button
          size="sm"
          variant="flat-secondary"
          class="btn-icon rounded-circle ml-auto"
          style="padding: 0.5rem 10px !important"
          @click="getSelectedRow()"
          :disabled="isNotSelectedRows"
        >
          <unicon name="trash-alt" width="18"></unicon>
        </b-button>
      </b-card-header>
      <b-card-body class="px-0">
        <vue-good-table
          @on-selected-rows-change="selectionChanged"
          :columns="columns"
          :rows="packagesActivePage"
          :rtl="false"
          :small="true"
          :row-style-class="rowStyleIsOffer"
          styleClass="vgt-table condensed"
          ref="package-table"
          :select-options="{
            enabled: true,
            selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
            selectionInfoClass: 'custom-class',
            selectionText: 'rows selected',
            clearSelectionText: 'clear',
            disableSelectInfo: true, // disable the select info panel on top
            selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
          }"
        >
          <template slot="table-row" slot-scope="props">
            <!-- Column: Status -->
            <span v-if="props.column.field === 'endDate'">
              {{ new Date(props.row.endDate).toISOString().substr(0, 10) }}
            </span>
            <span v-else-if="props.column.field === 'price'">
              {{ props.row.price | numFormat }} ل.س
            </span>
            <span v-else-if="props.column.field === 'status'">
              <b-badge
                :variant="
                  statusVariant(
                    new Date(props.row.endDate) > new Date() ? 0 : 1
                  )
                "
              >
                {{
                  new Date(props.row.endDate) > new Date() ? "مفعل" : "غير مفعل"
                }}
              </b-badge>
              <b-badge
                class="mx-1"
                :variant="statusVariant(props.row.isHidden ? 3 : 2)"
              >
                {{ props.row.isHidden ? "مخفي" : "ظاهر" }}
              </b-badge>
            </span>
            <!-- Column: Action -->
            <span v-else-if="props.column.field === 'details'">
              <b-button
                :to="`/codes-packages/packages/${props.row.id}`"
                size="sm"
                variant="flat-secondary"
                class="btn-icon rounded-circle"
                style="padding: 2px 6px !important"
              >
                <unicon name="ellipsis-v" width="18"></unicon>
              </b-button>
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
      </b-card-body>
    </b-card>
    <b-col cols="12" class="d-flex justify-content-center">
      <b-pagination
        :value="packagesFirstPage"
        :total-rows="mainPackagesList.length"
        :per-page="packagesPageLength"
        first-number
        last-number
        prev-class="prev-item"
        next-class="next-item"
        class="mt-1 mb-0"
        @input="(value) => updatePackagesPagination(value)"
      >
        <template #prev-text>
          <unicon width="20" name="angle-right" fill="royalblue" />
        </template>
        <template #next-text>
          <unicon width="20" name="angle-right" fill="royalblue" />
        </template>
      </b-pagination>
    </b-col>
  </div>
  <div v-else class="text-center justify-content-center mt-2">
    <h4>لا يوجد حزم متاحة</h4>
  </div>
</template>

<style>
.isOffer {
  background-color: rgba(253, 184, 68, 0.29);
}
</style>

<script>
import {
  BBadge,
  BPagination,
  BButton,
  BCard,
  BCardHeader,
  BCardBody,
  BCol,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import store from "@/store";
import { mapActions, mapGetters } from "vuex";
import Swal from "sweetalert2";

export default {
  components: {
    VueGoodTable,
    BBadge,
    BPagination,
    BButton,
    BCard,
    BCardHeader,
    BCardBody,
    BCol,
  },
  data() {
    return {
      isNotSelectedRows: true,
      pageLength: 10,
      columns: [
        {
          label: "اسم الحزمة",
          field: "name",
        },
        {
          label: "عدد الرموز",
          field: "codesCount",
          type: "number",
          thClass: "vgt-left-align sortable",
          tdClass: "vgt-left-align",
        },
        {
          label: "تاريخ الانتهاء",
          field: "endDate",
          sortable: true,
        },
        {
          label: "كلفة الحزمة",
          field: "price",
          type: "number",
          thClass: "vgt-left-align sortable",
          tdClass: "vgt-left-align",
        },
        {
          label: "حالة الحزمة",
          field: "status",
          sortable: false,
        },
        {
          label: "تفاصيل",
          field: "details",
          sortable: false,
        },
      ],
    };
  },
  mounted() {
    this.fetchPackages();
    store.commit("app/UPDATE_CREATE_BUTTON_AND_GLOBALE_SEARCH", {
      isActive: true,
      text: "حزمة جديدة",
      fetchingFunction: this.createPackage,
      placeHolder: "ابحث عن حزمة محددة",
      value: "",
      filterFunc: this.filterPackages,
    });
  },
  computed: {
    ...mapGetters([
      "mainPackagesList",
      "packagesActivePage",
      "packagesFirstPage",
      "packagesPageLength",
    ]),
    statusVariant() {
      const statusColor = {
        0: "light-success", //مفعل
        1: "light-danger", // غير مغعل
        2: "light-primary", // ظاهر
        3: "light-warning", // مخفي
      };

      return (status) => statusColor[status];
    },
  },
  methods: {
    rowStyleIsOffer(row) {
      return row.packageType == 2 ? "isOffer" : "";
    },
    ...mapActions([
      "fetchPackages",
      "createPackage",
      "filterPackages",
      "updatePackagesPagination",
      "removePackages",
    ]),
    selectionChanged() {
      this.isNotSelectedRows = !this.$refs["package-table"].selectedRows.length;
    },
    getSelectedRow() {
      this.ids = [];
      this.$refs["package-table"].selectedRows.forEach((element) => {
        this.ids.push(element.id);
      });
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: true,
      });
      swalWithBootstrapButtons
        .fire({
          title: "هل أنت متأكد؟",
          text:
            "قد يؤدي حذف الحزمة لحذف حسابات مالية غير معالجة، يرجى التأكد من ذلك قبل الحذف.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "نعم!",
          cancelButtonText: "إلغاء",
          reverseButtons: false,
        })
        .then((result) => {
          if (result.value) {
            this.removePackages(this.ids);
          }
        });
    },
  },
};
</script>
